import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Breadcrumbs from 'components/Breadcrumbs';
import SimpleBanner from 'components/SimpleBanner';
import TitleAndDownload from 'components/TitleAndDownload';
import TitleAndDownloadItem from 'components/TitleAndDownload/TitleAndDownloadItem';

import { PolicyDetailPageTypes } from './models';
import PolicyDetailBottomBodyRenderer from './PolicyDetailBottomBodyRenderer';
import PolicyDetailTopBodyRenderer from './PolicyDetailTopBodyRenderer';

import '../ArticlePage/ArticlePage.scss';

const PolicyDetailPage: FC<PolicyDetailPageTypes.PolicyDetailPageType> = ({
  data: { policiesAndReportsPage, policyDetailPage },
}) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } =
    policyDetailPage.seo;
  const { pdfDownloadLinkText } = policiesAndReportsPage;
  const {
    level,
    title,
    banner,
    isHtmlPageAvailable,
    pdfDownloadLink,
    topModules,
    moreLinks,
    bottomModules,
    url,
    lang,
  } = policyDetailPage;

  return (
    <Layout headerTransparent className="article-page">
      <Seo
        {...{
          seo: policyDetailPage.seo,
          openGraph: policyDetailPage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />

      <div className="banners">
        <Breadcrumbs level={level} />
        <SimpleBanner {...banner} />
      </div>

      <div id="modules">
        {isHtmlPageAvailable ? (
          <div>
            <PolicyDetailTopBodyRenderer modules={topModules} />
            {moreLinks ? <TitleAndDownload module={{ items: moreLinks }} /> : null}
            <PolicyDetailBottomBodyRenderer modules={bottomModules} />
          </div>
        ) : (
          <div>
            {pdfDownloadLink && pdfDownloadLink.length ? (
              <TitleAndDownloadItem title={pdfDownloadLinkText} ctaLink={pdfDownloadLink} />
            ) : null}
          </div>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $landingPageId: Int) {
    policiesAndReportsPage(pageId: { eq: $landingPageId }) {
      pdfDownloadLinkText
    }
    policyDetailPage(url: { eq: $url }) {
      pageId
      level
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentSimpleBanner
      }
      isHtmlPageAvailable
      pdfDownloadLink {
        ...FragmentLink
      }
      topModules {
        ...FragmentPolicyDetailTopModules
      }
      moreLinks {
        title
        ctaLink {
          ...FragmentLink
        }
      }
      bottomModules {
        ...FragmentPolicyDetailBottomModules
      }
    }
  }
`;

export default PolicyDetailPage;

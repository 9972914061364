import { getImage, IArtDirectedImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { ImageBreakpoint, ResponsiveImage } from './models';

export const getArtDirectedImages = (
  breakpoints: ImageBreakpoint[],
  imageData: ResponsiveImage
): IArtDirectedImage[] =>
  breakpoints
    .filter(({ alias }) => !!getImage(imageData[alias]))
    .map(({ alias, media }) => ({
      media,
      image: getImage(imageData[alias]) as IGatsbyImageData,
    }));

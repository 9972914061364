import React, { FC, useContext } from 'react';

import Download from '../../common/icons/arrow-down.svg';
import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { DownloadLinkProps } from './models';

import './DownloadLink.scss';

const DownloadLink: FC<DownloadLinkProps> = ({ pdfReportDownload }) => {
  const { isMobile, isSmallMobile } = useContext(ScreenRecognitionContext);

  if (!pdfReportDownload || !pdfReportDownload.length || isMobile || isSmallMobile) return null;

  const [firstLink] = pdfReportDownload;

  return (
    <a
      className="download-link__download link link--download"
      href={firstLink.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="download-link__download-text">{firstLink.name}</span>
      <Download aria-hidden="true" focusable="false" />
    </a>
  );
};

export default DownloadLink;

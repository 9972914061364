import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import ScreenRecognitionContext from '../../contexts/screenRecognitionContext';
import { BannerBaseProps, HeadlineProps, SubHeadingProps, TextProps } from './models';

const SubHeading: FC<SubHeadingProps> = ({ subheading, bannerClassName, isTablet, isDesktop }) => {
  if (!subheading) return null;

  return (
    <span className={classNames(`${bannerClassName}__sub-title`, { h6: isTablet || isDesktop })}>
      {subheading}
    </span>
  );
};

const Headline: FC<HeadlineProps> = ({ headlineText, bannerClassName }) => {
  if (!headlineText) return null;

  return <h1 className={`${bannerClassName}__title h1`}>{headlineText}</h1>;
};

const Text: FC<TextProps> = ({ description, bannerClassName }) => {
  if (!description) return null;

  return <p className={`${bannerClassName}__text standfirst`}>{description}</p>;
};

const BannerBase: FC<BannerBaseProps> = ({
  bannerClassName,
  subheading,
  headlineText,
  description,
}) => {
  const { isDesktop, isTablet } = useContext(ScreenRecognitionContext);

  return (
    <>
      <SubHeading
        subheading={subheading}
        bannerClassName={bannerClassName}
        isDesktop={isDesktop}
        isTablet={isTablet}
      />
      <Headline headlineText={headlineText} bannerClassName={bannerClassName} />
      <Text description={description} bannerClassName={bannerClassName} />
    </>
  );
};

export default BannerBase;

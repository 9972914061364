import React, { FC } from 'react';

import InternalExternalLink from 'common/InternalExternalLink';

import { LinkItemProps } from './models';

const LinkItem: FC<LinkItemProps> = ({
  children,
  link,
  linkClass,
  title,
  titleClass,
  ariaLabel,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  iconClass,
  hasIcon,
  isLink,
  showTitle,
}) => {
  if (!link?.length) return null;
  const [firstLink] = link;

  return (
    <InternalExternalLink
      classNameLink={linkClass}
      classNameTitle={titleClass}
      url={firstLink.url + (firstLink.queryString || '')}
      title={title || firstLink.name}
      target={firstLink.target}
      ariaLabel={ariaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={tabIndex}
      iconClass={iconClass}
      hasIcon={hasIcon}
      isLink={isLink}
      showTitle={showTitle}
    >
      {children}
    </InternalExternalLink>
  );
};

export default LinkItem;

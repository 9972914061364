export const mediaBannerColorHandler = (colorStyle: string): string =>
  typeof colorStyle === 'undefined' ? 'banner-media--gray' : colorStyle;

export const bannerWithCommitmentCardsColorHandler = (colorStyle: string): string =>
  typeof colorStyle === 'undefined' ? '' : colorStyle;

export const commonColorHandler = (colorStyle: string): string =>
  typeof colorStyle === 'undefined' ? '' : colorStyle;

export const hexToRgb = (hex) =>
  hex
    ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

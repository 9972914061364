import React, { FC } from 'react';

import ContentBlock from 'components/ContentBlock';
import PlainAccordion from 'components/PlainAccordion';
import TitleAndDownload from 'components/TitleAndDownload';

import { PolicyDetailBottomBodyRendererProps } from './models';

const Components = {
  'Plain Accordion': PlainAccordion,
  'Title And Download': TitleAndDownload,
  'Content Block': ContentBlock,
};

const PolicyDetailBottomBodyRenderer: FC<PolicyDetailBottomBodyRendererProps> = ({ modules }) => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return <Component key={`${block.structure}${index}`} module={block.module} />;
    })}
  </>
);

export default PolicyDetailBottomBodyRenderer;
